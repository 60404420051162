<template>
  <div class="pricing-strategy-container">
    <!-- 竞价底价策略 -->
    <a-form-model-item prop="bidfloorStrategy">
      <m-tips
        iconPosition="left"
        slot="label"
        :content="
          `竞价底价：当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。` +
            `</br>` +
            `策略：` +
            `</br>` +
            `1)媒体优先：以媒体请求时传入的底价为准。` +
            `</br>` +
            `(2)媒体底价百分比：对媒体传入的底价按百分比向上浮动。` +
            `</br>` +
            `(3)平台自定义：以平台设置的底价为准。` +
            `</br>` +
            `注意SDK接入，设置底价，需使用平台自定义。`
        "
        :title="'竞价底价策略'"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.bidfloorStrategy" size="medium" @change="changeBidfloorStrategy">
        <el-radio label="0" border>媒体优先</el-radio>
        <el-radio label="1" border>媒体底价百分比</el-radio>
        <el-radio label="2" border>平台自定义</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <!-- 竞价底价 -->
    <a-form-model-item
      v-if="+formquery.bidfloorStrategy === 2"
      :rules="[{ required: true, validator: validatorBidfloor, trigger: 'blur' }]"
      prop="bidfloorValue"
    >
      <m-tips
        iconPosition="left"
        slot="label"
        :content="`RTB竞价广告源竞价底价：当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。`"
        :title="'竞价底价'"
        :width="200"
      ></m-tips>
      <a-input type="number" :min="0" v-model.number.trim="query.bidfloorValue" placeholder="请输入竞价底价">
        <span class="percent-text" slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- 底价百分比值 -->
    <a-form-model-item
      label="底价百分比值"
      prop="bidfloorValue"
      v-if="+formquery.bidfloorStrategy === 1"
      :rules="[{ required: true, validator: validatorBidfloorFloatingRate, trigger: 'blur' }]"
    >
      <a-input type="number" :min="0" :max="100" v-model.number.trim="query.bidfloorValue" placeholder="请输入底价百分比值">
        <span class="percent-text" slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <a-form-model-item label="媒体底价定制">
      <div class="switch-add-box">
        <a-switch size="small" :checked="+formquery.mediaBidfloorCustomSwitch === 1" @change="handleChangeMediaCustomSwitch"></a-switch>
        <a-button type="primary" v-if="+formquery.mediaBidfloorCustomSwitch === 1" @click="handleAddMediaCustom">新增策略</a-button>
        <a-button type="link" v-else></a-button>
      </div>
    </a-form-model-item>
    <template v-if="+formquery.mediaBidfloorCustomSwitch === 1">
      <div class="media-custom-item" v-for="(cust, index) in formquery.mediaBidfloorCustomTacticVOS" :key="index">
        <a-form-model-item :label="`媒体底价定制${index + 1}`">
          <div class="delete-box">
            <a-icon type="close-circle" class="close-icon" @click="handleDeleteMediaCustomItem(index)"/>
          </div>
        </a-form-model-item>
        <a-form-model-item label="底价范围">
          <div class="input-box">
            <a-input
              type="number"
              :min="0"
              :max="cust.maxBidfloor || 100000000"
              v-model.number.trim="cust.minBidfloor"
              placeholder="最小值"
              style="width: 150px"
            >
              <span class="percent-text" slot="suffix">{{ moneyType }}</span>
            </a-input>
            <span style="margin: 0 5px">-</span>
            <a-input
              type="number"
              :min="cust.minBidfloor || 0"
              v-model.number.trim="cust.maxBidfloor"
              placeholder="最大值"
              style="width: 150px"
            >
              <span class="percent-text" slot="suffix">{{ moneyType }}</span>
            </a-input>
          </div>
        </a-form-model-item>
        <a-form-model-item label="策略" :prop="`mediaBidfloorCustomTacticVOS[${index}].type`">
          <el-radio-group v-model="cust.type" size="medium" @change="cust.value = undefined">
            <el-radio :label="0" border>默认</el-radio>
            <el-radio :label="1" border>百分比（%）</el-radio>
            <el-radio :label="2" border>自定义</el-radio>
            <el-radio :label="-1" border>过滤</el-radio>
          </el-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="showMediaCustomValue(cust)" :label="mediaCustomValueLabel(cust)" :prop="`mediaBidfloorCustomTacticVOS[${index}].value`">
          <a-input
            type="number"
            v-model.number.trim="cust.value"
            :placeholder="`最大值${mediaCustomValueLabel(cust)}`"
          >
            <span class="percent-text" slot="suffix">{{ cust.type === 1 ? '%' : moneyType }}</span>
          </a-input>
        </a-form-model-item>
      </div>
    </template>
    <!-- 媒体出价定制策略 -->
    <a-form-model-item prop="userDefinedSts" v-if="showUserDefine">
      <m-tips
        iconPosition="left"
        slot="label"
        content="该策略开启后，可以将广告响应的价格分区间处理，不同的价格区间给媒体返回不同的价格；出价策略有三种：透传、百分比浮动、平台自定义；如果广告响应的价格不在设置的区间内，则可通过【媒体出价策略】进行过滤处理。"
        :title="'媒体出价定制策略'"
        :width="250"
      ></m-tips>
      <div class="radio-add-box">
        <a-radio-group v-model="query.userDefinedSts" @change="changeSts">
          <a-radio value="A"> 开启 </a-radio>
          <a-radio value="S"> 关闭 </a-radio>
        </a-radio-group>
        <a-button v-if="formquery.userDefinedSts === 'A'" @click="handleAddStrategy" size="small" type="link">
          添加策略
        </a-button>
      </div>
    </a-form-model-item>
    <template v-for="(item, index) in formquery.priceStrategyList">
      <div class="priceStrategy-item" v-if="formquery.userDefinedSts === 'A' && showUserDefine" :key="index">
        <a-form-model-item :label="`出价定制策略${index + 1}`">
          <div class="delete-btn"><a-icon @click="deleteStrategy(index)" class="close-icon" type="close" /></div>
        </a-form-model-item>
        <a-form-model-item label="广告平台出价">
          <div class="input-box">
            <a-input
              type="number"
              :min="0"
              :max="item.max || 100000000"
              v-model.number.trim="item.min"
              placeholder="最小值"
              style="width: 150px"
            >
              <span class="percent-text" slot="suffix">{{ moneyType }}</span>
            </a-input>
            <span style="margin: 0 5px">-</span>
            <a-input
              type="number"
              :min="item.min || 0"
              v-model.number.trim="item.max"
              placeholder="最大值"
              style="width: 150px"
            >
              <span class="percent-text" slot="suffix">{{ moneyType }}</span>
            </a-input>
          </div>
        </a-form-model-item>
        <a-form-model-item label="媒体出价策略">
          <a-select v-model="item.type">
            <a-select-option v-for="(child, i) in typeList" :key="i" :value="child.value">
              {{ child.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="固定底价" v-if="item.type === '2'">
          <a-input type="number" :min="0" v-model.number.trim="item.value" placeholder="固定底价">
            <span class="percent-text" slot="suffix">{{ moneyType }}</span>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="价格浮动" v-if="item.type === '1'">
          <a-input type="number" :min="0" v-model.number.trim="item.value" placeholder="请输入价格浮动">
            <span class="percent-text" slot="suffix">%</span>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="出价范围" v-if="item.type === '3'">
          <a-input type="number" :min="0" v-model.number.trim="item.minRange" style="width: 150px" placeholder="最小值">
          </a-input>
          <span style="margin: 0 5px">-</span>
          <a-input
            type="number"
            :min="item.minRange || 0"
            v-model.number.trim="item.maxRange"
            style="width: 150px"
            placeholder="最大值"
          >
          </a-input>
        </a-form-model-item>
      </div>
    </template>
    <MediaAndTopStrategyForm v-on="$listeners" :query="formquery"></MediaAndTopStrategyForm>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MediaAndTopStrategyForm from '../MediaAndTopStrategyForm'
export default {
  name: 'PricingStrategyFormForAdx',
  components: { MediaAndTopStrategyForm },
  data () {
    return {
      typeList: [
        {
          label: '透传',
          value: '0'
        },
        {
          label: '百分比浮动',
          value: '1'
        },
        {
          label: '平台自定义',
          value: '2'
        },
        {
          label: '范围内随机',
          value: '3'
        }
      ]
    }
  },
  created () {
    this.$set(this.formquery, 'bidfloorStrategy', this.query.bidfloorStrategy || '0')
    this.$set(this.formquery, 'bidfloorValue', this.query.bidfloorValue || 0.1)
    this.$set(this.formquery, 'userDefinedSts', this.query.userDefinedSts || 'S')
    this.$set(this.formquery, 'priceStrategyList', this.query.priceStrategyList || [])
    this.$set(this.formquery, 'mediaBidfloorCustomSwitch', this.query.mediaBidfloorCustomSwitch || 0)
    this.$set(this.formquery, 'mediaBidfloorCustomTacticVOS', this.query.mediaBidfloorCustomTacticVOS || [])
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        this.$emit('changeQuery', val)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      sensitivity: (state) => state.asyncPermission.sensitivity,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  props: {
    query: {
      default: () => ({
      }),
      type: Object
    },
    showUserDefine: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    showMediaCustomValue (cust) {
      const { type = 0 } = cust
      return type === 2 || type === 1
    },
    mediaCustomValueLabel (cust) {
      const { type = 0 } = cust
      switch (+type) {
        case 1:
          return '底价百分比值'
        case 2:
          return '固定底价'
        default:
          return ''
      }
    },
    handleDeleteMediaCustomItem (index) {
      this.formquery.mediaBidfloorCustomTacticVOS.splice(index, 1)
    },
    handleAddMediaCustom () {
      this.formquery.mediaBidfloorCustomTacticVOS.push({
        maxBidfloor: undefined,
        minBidfloor: undefined,
        type: 0,
        value: undefined
      })
    },
    handleChangeMediaCustomSwitch (e) {
      this.formquery.mediaBidfloorCustomSwitch = e ? 1 : 0
    },
    changeBidfloorStrategy () {
      switch (+this.formquery.bidfloorStrategy) {
        case 1:
          this.formquery.bidfloorValue = 0
          break
        case 2:
          this.formquery.bidfloorValue = 0.1
          break
        default:
          break
      }
    },
    validatorBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入竞价底价！'))
      } else if (value < 0.01) {
        return callback(new Error('竞价底价必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorBidfloorFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入底价百分比值！'))
      } else if (value < 0.01) {
        return callback(new Error('底价百分比值必须大于0.01！'))
      } else if (value > 100) {
        return callback(new Error('底价百分比值必须小于100！'))
      } else {
        return callback()
      }
    },
    changeSts (e) {
      if (e) {
        this.formquery.priceStrategyList = [
          {
            type: '0',
            value: undefined,
            min: undefined,
            max: undefined,
            minRange: undefined,
            maxRange: undefined
          }
        ]
      }
    },
    handleAddStrategy () {
      this.formquery.priceStrategyList.push({
        type: '0',
        value: undefined,
        min: undefined,
        max: undefined
      })
    },
    deleteStrategy (index) {
      this.formquery.priceStrategyList.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.pricing-strategy-container .number-input-form.ant-row.ant-form-item {
  margin-bottom: 0;
}
::v-deep.pricing-strategy-container {
  .el-input__inner {
    border-radius: 4px 0 0 4px;
  }
  .priceStrategy-item {
    .ant-form-item:last-child .ant-form-item-control-wrapper {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(21, 34, 50, 0.2);
    }
  }
}
.pricing-strategy-container {
  .radio-add-box {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .input-box {
    display: flex;

    .middle {
      margin: 0 10px;
      display: inline-block;
    }
  }
  .delete-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    .close-icon {
      width: 12px;
      height: 12px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .number-input {
    display: flex;
    align-items: center;
    .input {
      width: 100%;
    }
    .suffix {
      display: inline-block;
      border-top: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      border-left: 0px;
      border-radius: 0 3px 3px 0;
      background-color: #fafafa;
      text-align: center;
      height: 36px;
      line-height: 36px;
      width: 40px;
    }
  }
  .switch-add-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  }
  .delete-box{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    .close-icon{
      cursor: pointer;
    }
  }
  .media-custom-item{
    border: 1px solid @compBorderColor;
    border-radius: @smallRadius;
    padding: 20px;
    margin-bottom: 20px;
  }
}
</style>
